import { render, staticRenderFns } from "./PopUpCitationStudies.vue?vue&type=template&id=b382e4a2"
import script from "./PopUpCitationStudies.vue?vue&type=script&lang=js"
export * from "./PopUpCitationStudies.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports