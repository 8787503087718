<template>
    <div class="publication-list">
      <kendo-datasource
        :ref="'publicationDiscrepancyDataSource'"
        :data="publicationDiscrepancyDataSource.data">
      </kendo-datasource>
      <kendo-grid
        :id="'publications-list-grid'"
        :ref="'publicationListGrid'"
        :data-source-ref="'publicationDiscrepancyDataSource'"
        style="publication-list-grid"
        :groupable="groupable"
        :selectable="selectable"
        :filterable="filterable"
        :sortable="sortable"
        :pageable="pageable"
        :columns="columns"
        :toolbar="toolbar"
        :scrollable="scrollable">
      </kendo-grid>
    </div>
</template>

<script>
import PublicationDiscrepancyDataSource from '@/assets/data/PublicationDiscrepancy.json'

export default {
  name: 'publication-list-discrepancies',
  data () {
    return {
      pageable: {
        refresh: true,
        pageSizes: true,
        buttonCount: 2,
        pageSize: 5
      },
      columns: [
        {
          headerAttributes: { class: 'grid-header-gray' },
          columns: [
            { field: 'PublicationIdentity', title: 'Citation ID', width: '1fr', filterable: false, headerAttributes: { class: 'grid-header-gray' } },
            { field: 'OriginalStage', title: 'Original Stage', width: '1fr', headerAttributes: { class: 'grid-header-gray' } },
            { field: 'OriginalStage', title: 'Reconciled', width: '1fr', headerAttributes: { class: 'grid-header-gray' } }
          ]
        },
        {
          title: 'Reviewer 1',
          headerAttributes: { class: 'grid-header-light' },
          columns: [
            { field: 'DE1To', title: 'To', width: '1fr', filterable: false, headerAttributes: { class: 'grid-header-light' } },
            { field: 'DE1By', title: 'By', width: '1fr', filterable: false, headerAttributes: { class: 'grid-header-light' } },
            { field: 'DE1Date', title: 'Date', width: '1fr', filterable: false, headerAttributes: { class: 'grid-header-light' } }
          ]
        },
        {
          title: 'Reviewer 2',
          headerAttributes: { class: 'grid-header-dark' },
          columns: [
            { field: 'DE2To', title: 'To', width: '1fr', filterable: false, headerAttributes: { class: 'grid-header-dark' } },
            { field: 'DE2By', title: 'By', width: '1fr', filterable: false, headerAttributes: { class: 'grid-header-dark' } },
            { field: 'DE2Date', title: 'Date', width: '1fr', filterable: false, headerAttributes: { class: 'grid-header-dark' } }
          ]
        }
      ],
      filterable: false,
      groupable: false,
      selectable: 'multiple row',
      sortable: true,
      scrollable: true,
      publicationDiscrepancyDataSource: PublicationDiscrepancyDataSource
    }
  },
  mounted: function () {
    this.gridWidget = this.$refs.publicationListGrid.kendoWidget()
    this.gridWidget.refresh()
  }
}
</script>
