<template>
  <div class="splitter-container">
    <div class="panel-header">
        <div class="panel-header-title">{{ title }}</div>
        <div class="panel-header-pins">
          <a href="#" style="transform: rotate(90deg);" v-if="swap" :title="'Swap'">
              <span class="k-icon k-i-arrows-swap"></span>
          </a>
          <a href="#" @click="openStudyCitationsList()" v-if="filterstudycitations" :title="title">
            <span class="k-icon k-i-filter"></span>
          </a>
          <a href="#" @click="openCitationStudiesList()" v-if="filterCitationStudies" :title="title">
            <span class="k-icon k-i-filter"></span>
          </a>
          <a href="#" @click="openCitationStudiesList()" v-if="filter" :title="filtertitle">
            <span class="k-icon k-i-filter"></span>
          </a>
          <a href="#" v-if="info" :title="infotitle">
            <span class="k-icon k-i-info"></span>
          </a>
          <a href="#" @click="toggleDetach()" v-if="detach" :title="detachtitle">
            <span class="k-icon k-i-hyperlink-open"></span>
          </a>
        </div>
    </div>
    <component class="component" :is="component" :style="calculateHeight()" :editable="editable"></component>
    <kendo-window :ref="'detachWindow'"
                  :width="'100%'"
                  :title="title"
                  :visible="false"
                  :modal="true"
                  :top="'0px'">
        <component class="component" :is="component" :style="calculateHeight()"></component>
    </kendo-window>
    <kendo-window :ref="'popupStudyCitations'"
                  :width="'80%'"
                  :title="title"
                  :visible="false"
                  :modal="true"
                  :top="'0px'">
        <component class="component" :is="popup" :style="calculateHeight()"></component>
    </kendo-window>
    <kendo-window :ref="'popupCitationStudies'"
                  :width="'80%'"
                  :title="title"
                  :visible="false"
                  :modal="true"
                  :top="'0px'">
        <component class="component" :is="popup" :style="calculateHeight()"></component>
    </kendo-window>
  </div>
</template>

<script>
import Filters from '@/views/secure/cards/Filters'
import FiltersBatchProcessing from '@/views/secure/cards/FiltersBatchProcessing'
import PublicationList from '@/views/secure/cards/PublicationList'
import PublicationListDiscrepancies from '@/views/secure/cards/PublicationListDiscrepancies'
import PublicationListValidation from '@/views/secure/cards/PublicationListValidation'
import PublicationListBatchProcessing from '@/views/secure/cards/PublicationListBatchProcessing'
import Annotations from '@/views/secure/cards/Annotations'
import AnnotationsBatchProcessing from '@/views/secure/cards/AnnotationsBatchProcessing'
import Abstract from '@/views/secure/cards/Abstract'
import AbstractBatchProcessing from '@/views/secure/cards/AbstractBatchProcessing'
import Citation from '@/views/secure/cards/Citation'
import CitationBatchProcessing from '@/views/secure/cards/CitationBatchProcessing'
import ActionsManageStagesForms from '@/views/secure/cards/ActionsManageStagesForms'
import ActionsNavigateCitations from '@/views/secure/cards/ActionsNavigateCitations'
import ActionsNavigateCitationStudies from '@/views/secure/cards/ActionsNavigateCitationStudies'
import ActionsNavigateStudies from '@/views/secure/cards/ActionsNavigateStudies'
import ActionsDE from '@/views/secure/cards/ActionsDE'
import ActionsBatch from '@/views/secure/cards/ActionsBatch'
import ActionsStageDiscrepancies from '@/views/secure/cards/ActionsStageDiscrepancies'
import ActionsValidation from '@/views/secure/cards/ActionsValidation'
import ActionsBatchProcessing from '@/views/secure/cards/ActionsBatchProcessing'
import Forms from '@/views/secure/cards/Forms'
import FullText from '@/views/secure/cards/FullText'
import FullTextBatchProcessing from '@/views/secure/cards/FullTextBatchProcessing'
import PopUpCitationStudies from '@/views/secure/cards/PopUpCitationStudies'
import PopUpStudyCitations from '@/views/secure/cards/PopUpStudyCitations'

export default {
  name: 'SplitterContainer',
  props: ['title', 'detach', 'detachtitle', 'swap', 'swaptitle', 'filter', 'filterstudycitations', 'filtercitationstudies', 'info', 'infotitle', 'component', 'popup', 'editable'],
  components: {
    Filters,
    FiltersBatchProcessing,
    PublicationList,
    PublicationListDiscrepancies,
    PublicationListValidation,
    PublicationListBatchProcessing,
    Annotations,
    AnnotationsBatchProcessing,
    Abstract,
    AbstractBatchProcessing,
    Citation,
    CitationBatchProcessing,
    ActionsManageStagesForms,
    ActionsNavigateCitations,
    ActionsNavigateStudies,
    ActionsNavigateCitationStudies,
    ActionsDE,
    ActionsBatch,
    ActionsStageDiscrepancies,
    ActionsValidation,
    ActionsBatchProcessing,
    Forms,
    FullText,
    FullTextBatchProcessing,
    PopUpCitationStudies,
    PopUpStudyCitations
  },
  methods: {
    calculateHeight: function () {
      if (this.component === 'publication-list' | this.component === 'publication-list-discrepancies') {
        return 'height: calc(100% - 90px)'
      } else {
        return 'height: calc(100% - 30px)'
      }
    },
    toggleDetach: function () {
      var windowWidget = this.$refs.detachWindow.kendoWidget()
      windowWidget.open()
      windowWidget.maximize()
    },
    openStudyCitationsList: function () {
      var windowWidget = this.$refs.popupStudyCitations.kendoWidget()
      windowWidget.open()
    },
    openCitationStudiesList: function () {
      var windowWidget = this.$refs.popupCitationStudies.kendoWidget()
      windowWidget.open()
    }
  },
  mounted: function () {
    console.log(this.info)
  }
}
</script>

<style scoped>
  .k-icon:before {
    color: #263246 !important;
  }
</style>
