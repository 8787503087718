<template>
  <div class="citation" style="height: 150px;">
    <div class="citation-container">
      <div class="citation-questions">
        <kendo-datasource
          :ref="'citationDataSource'"
          :data="publicationDataSource.data"
          :filter="filter">
        </kendo-datasource>
        <kendo-listview
          :id="'citationListView'"
          class="citation-listview"
          :ref="'citationListView'"
          :auto-bind="autoBind"
          :data-source-ref="'citationDataSource'"
          :template="citationViewTemplate">
        </kendo-listview>
      </div>
    </div>
    <kendo-datasource
          :ref="'selectedStudyDataSource'"
          :data="studyDataSource.data"
          :filter="filter">
        </kendo-datasource>
        <kendo-grid
          :id="'selectedStudyGrid'"
          :ref="'selectedStudyGrid'"
          :data-source-ref="'selectedStudyDataSource'"
          :selectable="selectable">
          <kendo-grid-column field="StudyID" title="Selected Study ID"></kendo-grid-column>
          <kendo-grid-column field="StudyAcronym" title="Acronym"></kendo-grid-column>
          <kendo-grid-column field="StudyDescription" title="Description"></kendo-grid-column>
          <kendo-grid-column field="PrimaryPublication" title="Primary Publication"></kendo-grid-column>
        </kendo-grid>
  </div>
</template>

<script>
import PublicationDataSource from '@/assets/data/Publication.json'
import StudyDataSource from '@/assets/data/Study.json'
import CitationBatchProcessingViewTemplate from './templates/CitationBatchProcessingView'
import mitt from 'mitt'
const emitter = mitt()

export default {
  name: 'citation',
  props: ['editable'],
  methods: {
    citationViewTemplate: function (e) {
      return {
        template: CitationBatchProcessingViewTemplate,
        templateArgs: e
      }
    }
  },
  data () {
    return {
      isEdit: false,
      autoBind: true,
      publicationDataSource: PublicationDataSource,
      filter: { field: 'PublicationIdentity', operator: 'eq', value: this.$store.state.currentPublicationId },
      isStudyMapping: this.$store.state.isStudyMapping,
      studyDataSource: StudyDataSource
    }
  },
  created: function () {
    emitter.on('sendHighlightedText', (type, text) => {
      this.setHighlightedText(type, text)
    })
  },
  mounted: function () {
    if (this.isStudyMapping) {
      this.isEdit = true
      var citationWidget = this.$refs.citationListView.kendoWidget()
      citationWidget.edit(citationWidget.element.children().first())
    }
  }
}
</script>
