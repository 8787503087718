<template>
  <div class="abstract" @click="setAbstractFocus()" style="height: 150px;">
    <kendo-datasource
      :ref="'abstractDataSource'"
      :data="publicationDataSource.data"
      :filter="filter">
    </kendo-datasource>
    <kendo-listview
      class="abstract-listview"
      :ref="'abstractListView'"
      :data-source-ref="'abstractDataSource'"
      :template="itemTemplate"
      :edit-template="editTemplate">
    </kendo-listview>
  </div>
</template>

<script>
import PublicationDataSource from '@/assets/data/Publication.json'
import mitt from 'mitt'
const emitter = mitt()

export default {
  name: 'abstract',
  props: ['editable'],
  methods: {
    setAbstractFocus: function () {
      this.$store.state.currentFocus = 'Abstract'
    },
    generateItemTemplate: function () {
      var itemTemplateStart = `
        <div class="abstract-container">
          <div class="abstract-questions" id="abstractText">
            #:Abstract#
          </div>
          <div class="abstract-options">
            <a class="k-edit-button" href="\\\\#">Show/Hide Keywords</a>`
      var itemTemplateMiddle = `
            <div>
            </div>`
      var itemTemlateEnd = `
          </div>
            </div>`

      if (this.editable) {
        return itemTemplateStart + itemTemplateMiddle + itemTemlateEnd
      } else {
        return itemTemplateStart + itemTemlateEnd
      }
    },
    generateEditTemplate: function () {
      return `
        <div class="abstract-container">
          <div class="abstract-questions">
            <textarea style="width: 100%; height: 300px;">#:Abstract#</textarea>
          </div>
          <div class="abstract-options">
            <a class="k-edit-button" href="\\\\#">Show/Hide Keywords</a>
            <a disabled="disabled">Edit</a>
            <div>
              <a class="k-update-button" href="\\\\#">Apply</a>
              <a class="k-cancel-button" href="\\\\#">Cancel</a>
            </div>
          </div>
        </div>`
    },
    setHighlightedText: function (type, text) {
      if (type === 'Abstract') {
        var abstractHighlightedText = this.$options.filters.highlight(this.abstractDataSource[0].Abstract, text)
        document.getElementById('abstractText').innerHTML = abstractHighlightedText
      }
    }
  },
  data () {
    return {
      itemTemplate: this.generateItemTemplate(),
      editTemplate: this.generateEditTemplate(),
      publicationDataSource: PublicationDataSource,
      filter: { field: 'PublicationIdentity', operator: 'eq', value: this.$store.state.currentPublicationId }
    }
  },
  created: function () {
    emitter.on('sendHighlightedText', (type, text) => {
      this.setHighlightedText(type, text)
    })
  }
}
</script>
