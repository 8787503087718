<template>
  <div>
    <div style="display: flex; flex-direction: vertical; justify-content: space-between; width: 1200px;">
      <div class="action-buttons-nav" style>
            <b-button type="submit" variant="primary" size="sm" style ="width: 50px;">Previous</b-button>
            <b-button type="submit" variant="primary" size="sm" style ="width: 50px;">Next</b-button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <b-button type="submit" variant="primary" size="sm" style ="width: 50px;">Exit</b-button>
          </div>
      <div class="action-buttons-nav">
            <b-button type="submit" variant="primary" size="sm" style ="width: 50px;">Select</b-button>
            <b-button type="submit" disabled size="sm" style ="width: 50px;">Deselect</b-button>
      </div>
    </div>
    <kendo-splitter :panes="hSplitter1.panes" :orientation="hSplitter1.orientation" :scrollable="hSplitter1.scrollable">
      <kendo-splitter :panes="vSplitter1.panes" :orientation="vSplitter1.orientation" :scrollable="vSplitter1.scrollable" :collapsible="vSplitter1.collapsible">
        <kendo-splitter :panes="hSplitter2.panes" :orientation="hSplitter2.orientation" :scrollable="hSplitter2.scrollable">
          <div>
            <div class="panel-header">
              <div class="panel-header-title">Abstract</div>
            </div>
            <AbstractBatchProcessing></AbstractBatchProcessing>
          </div>
        </kendo-splitter>
        <kendo-splitter :panes="hSplitter2.panes" :orientation="hSplitter3.orientation" :scrollable="hSplitter2.scrollable">
          <div>
            <div class="panel-header">
              <div class="panel-header-title">Citation</div>
            </div>
            <CitationBatchProcessing></CitationBatchProcessing>
          </div>
          <div>
            <div class="panel-header">
              <div class="panel-header-title">Annotation</div>
            </div>
            <AnnotationsBatchProcessing></AnnotationsBatchProcessing>
          </div>
        </kendo-splitter>
      </kendo-splitter>
    </kendo-splitter>
  </div>
</template>

<script>
import AnnotationsBatchProcessing from '@/views/secure/cards/AnnotationsBatchProcessing'
import AbstractBatchProcessing from '@/views/secure/cards/AbstractBatchProcessing'
import CitationBatchProcessing from '@/views/secure/cards/CitationBatchProcessing'

export default {
  name: 'review',
  components: {
    CitationBatchProcessing,
    AbstractBatchProcessing,
    AnnotationsBatchProcessing
  },
  data () {
    return {
      highlightedtext: '',
      currentFocus: '',
      isProcess: false,
      hSplitter1: {
        scrollable: false,
        collapsible: false,
        orientation: 'horizontal',
        panes: [
          { collapsible: false, size: '20%' },
          { collapsible: true, size: '80%' }
        ]
      },
      hSplitter2: {
        scrollable: true,
        orientation: 'horizontal',
        panes: [
          { collapsible: false, size: '50%' },
          { collapsible: true, size: '50%' }
        ]
      },
      vSplitter1: {
        scrollable: true,
        orientation: 'vertical',
        panes: [
          { collapsible: false, size: '300px' },
          { collapsible: false, size: '400px' }
        ]
      },
      hSplitter3: {
        scrollable: false,
        orientation: 'horizontal',
        panes: [
          { collapsible: false, size: '50%' },
          { collapsible: true, size: '50%' }
        ]
      }
    }
  },
  mounted: function () {
    this.$store.state.selectedNavigation = 'process'
    this.$store.state.selectedAction = 'manage-forms'
    this.$store.state.showActions = true
  }
}
</script>

<style>
  .k-widget .k-splitter {
    height: 700px !important;
  }

  .k-pane .k-scrollable {
    height: 350px !important;
  }
</style>
